import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "toB 向け E ラーニングシステムに ChatGPT を導入して業務効率化を達成した事例について語ってもらいました",
  "date": "2023-11-30T12:10:50.000Z",
  "slug": "entry/2023/11/30/211050",
  "tags": [],
  "hero": "./2023-11-30.png",
  "heroAlt": "toB 向け E ラーニングシステムに ChatGPT を導入して業務効率化を達成した事例について語ってもらいました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`エンジニアの山田です。`}</p>
    <p>{`弊社の`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20230518.html"
      }}>{`ニュースリリース`}</a>{`にあるのですが、ジョブメドレーアカデミー(以下、アカデミー)では `}<a parentName="p" {...{
        "href": "https://azure.microsoft.com/ja-jp/products/ai-services/openai-service"
      }}>{`Azure OpenAI Service`}</a>{` の GPT モデルを駆使した新機能「`}<strong parentName="p">{`研修作成アシスタント`}</strong>{`」をリリースしています。 この生成 AI を活用した機能により研修作成担当者の作業時間を 75%軽減し、業務効率化に寄与するものになっています。`}</p>
    <p>{`今回はこの研修作成アシスタントの開発について、担当した二人に話を聞きました。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`岸田さん`}</h2>
    <p>{`SES 企業で業務システム開発、オフショア開発をしている会社でブリッジエンジニアという経験を経て、2019 年メドレー入社。
その後ジョブメドレーで開発をリード。現在はアカデミーのプロダクト責任者を務める。アカデミーのプロダクトに関する各施策を統括して管理している。`}</p>
    <h2>{`徳永さん`}</h2>
    <p>{`2022 年メドレーに新卒入社。
入社時からアカデミーの開発に携わる。大学では、対話 AI システム・ AI との対話用プラットフォームの研究開発をしていた。現在は自身も開発をしながら開発のリードにも挑戦している。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95703dbb232f98dad507c4dce097fea6/eea4a/202311_dev_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZtq3ZrJnh//xAAaEAACAgMAAAAAAAAAAAAAAAABAwACEBIz/9oACAEBAAEFAi/ZrryrQQnmzH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABwQAAICAgMAAAAAAAAAAAAAAAABAiEQURIigf/aAAgBAQAGPwLrGT46FG90XR6J4//EABoQAQEBAAMBAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8hY05RrHTEvpMFafx5xinrwOjmd43/2gAMAwEAAgADAAAAEJ/f/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAyP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EHST/8QAHRAAAgMAAgMAAAAAAAAAAAAAASEAETFBUXGBkf/aAAgBAQABPxCx7+QW1n3iAcA9oO+9yJ9LPYewjAEgASTQ1ypQyR15hGtn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "202311 dev 005",
            "title": "202311 dev 005",
            "src": "/static/95703dbb232f98dad507c4dce097fea6/e5166/202311_dev_005.jpg",
            "srcSet": ["/static/95703dbb232f98dad507c4dce097fea6/f93b5/202311_dev_005.jpg 300w", "/static/95703dbb232f98dad507c4dce097fea6/b4294/202311_dev_005.jpg 600w", "/static/95703dbb232f98dad507c4dce097fea6/e5166/202311_dev_005.jpg 1200w", "/static/95703dbb232f98dad507c4dce097fea6/eea4a/202311_dev_005.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`左: 岸田さん 右: 徳永さん`}</em></p>
    <h1>{`ジョブメドレーアカデミーのサービス概要`}</h1>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`今回アカデミーで採用した生成 AI を用いた新機能について話をしていきたいと思います。その前にアカデミーについてはこのブログでも何度か取り上げていますが、改めてアカデミーというプロダクトがどのようなサービス内容なのか教えてもらってもよいでしょうか?`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: アカデミーの概要としては、現在「介護」「障がい福祉」「訪問歯科」「在宅調剤」という `}<strong parentName="p">{`4 つの業種に対応した「オンライン動画研修サービス」`}</strong>{`となります。`}</p>
    <p>{`研修については、導入してくださっている顧客である介護施設側が、1 つ 1 つ研修内容に合わせてアカデミーに登録されている動画を組み合わせてカリキュラムを決めていき、職員の方たちはそれらの動画を見て、研修を受けてレポートを書いたりできるプロダクトになります。これに付随して研修そのものを計画したり管理できたりする、というとイメージしやすいでしょうか。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`介護施設の研修業務を効率化するというプロダクトですね。どのようなミッションを持って、開発しているんですか?`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ca824260a438d5a6ce6faa2dc8bf3a8f/eea4a/202311_dev_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAF0ltDVMCP/xAAcEAABAwUAAAAAAAAAAAAAAAAEAAEDAhESMzT/2gAIAQEAAQUCJmwYWRqo7ovYNzL/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAEAISIXGC/9oACAEBAAY/AkMdmISlzXmDuv/EABsQAAICAwEAAAAAAAAAAAAAAAERACFBUfAQ/9oACAEBAAE/IdnQdAQWjyPJiwi99Morr8//2gAMAwEAAgADAAAAEKPv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExUf/aAAgBAwEBPxBYOn//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EGmi1U//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhUWFx/9oACAEBAAE/EHp23XgYrlRdrVEDp45OZV43GmikY+2ahRXU+crv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "202311 dev 001",
            "title": "202311 dev 001",
            "src": "/static/ca824260a438d5a6ce6faa2dc8bf3a8f/e5166/202311_dev_001.jpg",
            "srcSet": ["/static/ca824260a438d5a6ce6faa2dc8bf3a8f/f93b5/202311_dev_001.jpg 300w", "/static/ca824260a438d5a6ce6faa2dc8bf3a8f/b4294/202311_dev_001.jpg 600w", "/static/ca824260a438d5a6ce6faa2dc8bf3a8f/e5166/202311_dev_001.jpg 1200w", "/static/ca824260a438d5a6ce6faa2dc8bf3a8f/eea4a/202311_dev_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 大前提として、提供している`}<strong parentName="p">{`研修によって施設で働く人のスキルアップに役立たせる`}</strong>{`、という目的があります。それ以外では BtoB のサービスなので`}<strong parentName="p">{`介護施設の事業効率化という点も重要視`}</strong>{`しています。本来、施設の本業は「介護」になるので、そこに本質的には関係しないような管理業務などは、アカデミーを使うことによって効率的に作業ができるようになる、というところを目指しています。`}</p>
    <h1>{`最近の業務内容`}</h1>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`介護施設が「介護」以外に時間を取られているという課題を解決するプロダクトなんですね。そんなアカデミーでお二人は現在どのような業務をされているのでしょう。`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 私は直近はチームとしてアカデミーの機能の一番コアな部分である研修システムの割り当ての改修をしています。今までは、施設職員への研修割り当ての機能が、小規模の施設だと少し使い勝手が悪く自由度が高くない機能だったんですが、その割り当て自由度を高めるためにコードレベルでばっさりと書き換えをするというプロジェクトを`}<strong parentName="p">{`エンジニア 7 人 + プロダクトマネージャ 1 人で進めており、そのプロジェクトの開発リード`}</strong>{`をしています。`}</p>
    <p>{`最初は 5 人で始めたプロジェクトでしたが、プロジェクト自体のボリュームも増えてきて現在の人数になりました。このプロジェクトは 11 月中のリリース予定になっています。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`かなり大きい規模のプロジェクトのリードをされているんですね! 岸田さんはいかがですか?`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 私は最近は開発に直接携わるよりも、プロダクト全体の方針決めなどをメインの業務にしています。次のクォーターではどんな開発をするべきか、来年はどのようにプロダクトを発展させていくかなどを考えるという仕事ですね。他にはアカデミーに在籍しているプロダクトマネージャ達の企画の精査だとか、コードレビューなどをしています。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`メンバーのピープルマネジメントなどは引き続きやっていらっしゃるんですか?`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: はい、そちらも引き続きやっています。全員の 1on1 などは最近は他のリードの方にお願いしていたりもします。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 現在のプロジェクトのメンバーの 1on1 は自分が担当させてもらっていることが多いです。主に話をする内容は開発に関する提案やディスカッションになります。`}</p>
    <h1>{`生成 AI を活用した研修作成アシスタントについて`}</h1>
    <h2>{`機能の概要について`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`ありがとうございます。最近のお二人のお仕事が分かったところで、本題である生成 AI を利用した「研修作成アシスタント」機能についてお話を伺えればと思います。ニュースリリースにも書いてあるのですが、こちらの機能の説明をざっくりとお願いしてよいでしょうか。`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 先程のサービス説明のときにもお話したのですが、研修を考えるときには施設の方は「どういった研修にするか」を考える必要があります。「どんなテーマの研修にするか」「今月は制度で決まっているから、この研修をしないといけない」など色々な理由はあるんですが、テーマが決まって研修を実施するまでに「テーマに合った動画を探して選択する」というプロセスが入ります。`}</p>
    <p>{`プロセスとしては他に「いつ誰に受けてもらうか決める」などもあるのですが、`}<strong parentName="p">{`研修カリキュラムを作成する一番のペインは「テーマに合った動画を探して選択する」という部分`}</strong>{`なんです。`}</p>
    <p>{`今までは、この「動画を選択する機能」としては以下の 2 つをプロダクトとしては用意していました。`}</p>
    <ol>
      <li parentName="ol">{`メドレー社内で選定された動画を研修のプリセットとして使える機能（頻出する研修のみ）`}</li>
      <li parentName="ol">{`一つひとつ動画を選んでオリジナルな研修を作成する機能`}</li>
    </ol>
    <p>{`この 2 つだと 0 か 100 かみたいな形で中間となる 50 にあたる機能がなく、ここに相当する`}<strong parentName="p">{`セミオーダーメイドのような機能が欲しいというのが以前からプロダクトで抱えていたニーズ`}</strong>{`だったんです。`}</p>
    <p>{`今回提供した「研修作成アシスタント」機能では、`}<strong parentName="p">{`大まかにどんな研修をやりたいかを研修名として入力してもらえれば、そこから生成 AI が「研修で具体的に学んでほしいこと」（研修の目的）や、「目的にマッチする動画」を提案`}</strong>{`してくれます。 管理者の方には生成 AI が提案してくる内容を取捨選択・訂正していただく形になっています。`}</p>
    <p>{`これによって既存の機能が抱えていた「多様なニーズへの対応ができない」「研修作成に時間がかかり過ぎる」という両方の課題を解決する機能というイメージです。`}</p>
    <h2>{`そもそもこの機能を開発した理由`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`「研修作成アシスタント」ですが、元々はどういった経緯で開発をすることになったんでしょうか。`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: アカデミーのサービスとしてのウリの 1 つでもあるんですが、動画を内製していてその数も 6,500 本以上に上ります。多くの場合 1 つの研修につき 5 本ほどの動画を設定するのですが、`}<strong parentName="p">{`数千本の動画の中から研修にぴったりと合った動画を見つけ出すのは大変な作業`}</strong>{`でした。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 研修を作るのに一番大事なのが動画選びなのは間違いないのですが、その他にも研修の目標を考えるのも同じくらい大事です。そもそも目標がないと、受講者もどういう背景でやる研修なのかが分からなかったり、`}<strong parentName="p">{`「目標設定をして研修をする」ことが介護保険の加算要件に入っている場合もある`}</strong>{`ので、この目標設定は大事なんですが、こちらも 1 から考えるとなると中々に苦労する部分だったりしていました。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`研修動画を選ぶのも、目標設定をするのも今までコストがかかっていたということですね。`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: こららのコストを何とか減らせないかというところが開発の立脚点になっています。研修名だけは考えてもらえれば、あとはこちらで自動生成して提案しますよという形になれば、`}<strong parentName="p">{`プロダクトが便利になるなという予感`}</strong>{`がありました。`}</p>
    <h2>{`アカデミーのサービス特性と ChatGPT の相性の良さ`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`実際に作業時間を 75%削減する機能となっており便利になっていますもんね。この機能の開発に ChatGPT を使っていますが、どうして ChatGPT を選んだんですか?`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: この機能を作ろうと動き出したのが、2023 年 3 月くらいだったのですが、時期的にちょうど GPT-4 が出るか出ないかというところでした。ですので、Google Bard など他の選択肢がそもそもなかった状態だったというのが理由の 1 つであります。`}</p>
    <p>{`あとは Azure のほうは生成系 AI を使っても、こちらのデータは学習に使われない環境を提供していて、プライバシーの側面も考えると、しっかりと業務で使える環境が整っていたのが ChatGPT だった…という感じでしたので、選びました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3236b46837e61ce9ef1b176e1eebfb94/eea4a/202311_dev_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQADBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFbjPQlRsaj/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAwQSFDH/2gAIAQEAAQUCsbVKLEDzKJ7IPP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AVn/xAAcEAABBQADAAAAAAAAAAAAAAABAAIQESEDUWH/2gAIAQEABj8COX4hxtaRYgb1AX//xAAaEAEBAAMBAQAAAAAAAAAAAAABABEhMaHB/9oACAEBAAE/Ie468W1CQ/JSBhRMBsLxX//aAAwDAQACAAMAAAAQXA//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QDSf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Qhqv/xAAbEAEAAwEAAwAAAAAAAAAAAAABABExQSGBwf/aAAgBAQABPxA0D1CoNPV4QLCK8ot+vMsbBcBCg1ZGwATEMjfQn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "202311 dev 003",
            "title": "202311 dev 003",
            "src": "/static/3236b46837e61ce9ef1b176e1eebfb94/e5166/202311_dev_003.jpg",
            "srcSet": ["/static/3236b46837e61ce9ef1b176e1eebfb94/f93b5/202311_dev_003.jpg 300w", "/static/3236b46837e61ce9ef1b176e1eebfb94/b4294/202311_dev_003.jpg 600w", "/static/3236b46837e61ce9ef1b176e1eebfb94/e5166/202311_dev_003.jpg 1200w", "/static/3236b46837e61ce9ef1b176e1eebfb94/eea4a/202311_dev_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`こうした新しい技術へ向き合う姿勢という点ではどういったものがありましたか?`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 今回特に ChatGPT など生成 AI が盛り上がってきたタイミングで、このまま行くと`}<strong parentName="p">{`生成 AI が使われていないと事業としても不利になっていくタイミングが来る`}</strong>{`んじゃないかなと考えました。一度触れてみて、技術の進化の分岐点ではないかと感じたので、このタイミングでちゃんと社内の効率化みたいなものをやりたいと最初は考えていました。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`社内でオペレーションをする方向けの機能を最初は考えていたということでしょうか。`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: そうです。アカデミーでは動画は内製しているため、企画の素案作りだとか動画の説明文を生成することなどを最初は考えていたんですが、アカデミーのプロダクトとして価値を高めるため`}<strong parentName="p">{`「社内ではなくて顧客向けの機能にしても良いのでないか」`}</strong>{`と思い直したんです。`}</p>
    <p>{`こうした点も考えると、生成 AI の導入によるデメリットというものがメリットに比べて限りなく少ないという判断ができましたので、元々課題として考えていた研修生成に使うことを決めました。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 最初は日本でも生成 AI をプロダクトに取り込んでいるのは数件程度ということもあったので、さっと取り入れてしまったほうがメリットがあると感じました。`}</p>
    <h2>{`研修作成アシスタントができるまで`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`最初はどんな感じで企画など進行したんでしょう?`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 最初は現在アカデミーで感じる課題に対して生成 AI でどのようなソリューションが出せるのかを思考実験という感じで Confluence （社内 wiki）にいくつか並行してまとめていきました。その中で一番費用対効果が高いというか、生成 AI を使わない従来の技術だと一番太刀打ちができない課題が「研修作成アシスタント」の機能でした。`}</p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 生成 AI を使わない場合のアプローチは考えてはいたんですが、結局のところ`}<strong parentName="p">{`検索を高性能にしていくか、おすすめをパッケージングするくらいしか効率的に動画を探す方法が見出せずにいました`}</strong>{`。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`動画の検索を高性能にということだと…検索に使うメタ情報をちゃんと用意して…というような手段になりそうですね。`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: アカデミーの動画だと説明文などが、必要最低限しか記録されていないことが多く、動画内の情報を取ってくるのも難しかったのです。ですので、中々コストがかかってきて改善が現実的じゃなかったんですが`}<strong parentName="p">{`「生成 AI を使えばこの問題を解決できそう」という感触がありました`}</strong>{`。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`なるほど、一番解決が難しい問題を生成 AI でコストを最小限で解決できそうということだったんですね。こうした企画から実際に実装するまではどんな進行をしたんですか?`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 最初は徳永さんと、もう 1 名のエンジニアとで 1 週間くらい検証作業をしていました。実際にちゃんとできるのかや、精度として顧客が満足するくらいまで出せるのかなどを検証していました。`}</p>
    <p>{`ある程度行けそうという目星が立ったのですが、その途中で先程の動画の説明文不足が、障害になるというのも分かってきました。これを解決するために`}<strong parentName="p">{`Google の `}<a parentName="strong" {...{
          "href": "https://cloud.google.com/speech-to-text?hl=ja"
        }}>{`Speech-to-Text`}</a>{` を使って動画内の文字起こしを自動でできるようにしてメタ情報を作るようにしたり、ベクトル検索ができるような外部サービスを導入した`}</strong>{`んです。それだけだと精度が低かったので、生成したメタ情報をさらに ChatGPT で整形するような方式を取りました。`}</p>
    <p>{`これらを DB に入れてベクトル検索をかけるようにして検索時に目標やタイトルと関連付けて検索できるようにしたという流れです。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`なるほど 3 段構えくらいで機能を実現しているんですね。`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: とはいえ生成 AI は API の出力結果が若干不安定だったりするので、どこで最初はエラーが出たのかというのが分かりにくかったりしたので苦労しました。検索も今の形になるまでは `}<a parentName="p" {...{
        "href": "https://opensearch.org/"
      }}>{`OpenSearch`}</a>{` の全文検索を使ったりしたんですが、検索精度が低かったりして紆余曲折はありました。そこから今の形式になり精度を高めて、合計 1 ヶ月くらいでチューニングまで含めてリリースしたという感じです。`}</p>
    <h2>{`今回のプロジェクトでの経験で学んだこと`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`今回のプロジェクトで良い経験だったと思う点はどんなものがありますか?`}</strong></p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 今回はあまり先行事例もないですし、調査と検証を繰り返していました。途中で色々問題も出たりしたのですが、やっぱり`}<strong parentName="p">{`未知の状態で物事を進めるにはひたすら PDCA を回していくのが一番良いんだなということを学びました`}</strong>{`。`}</p>
    <p>{`元々大学での研究が対話 AI などだったので、言語モデルをどう扱えばよいかなどの知識があったのは良かったなと思いましたね。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`では今回のプロジェクトに適任だったんですね!`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: あと学んだことではないですが、今回のプロジェクトの副産物としてベクトル化した動画の`}<strong parentName="p">{`メタ情報が手に入ったんで、これを発展させてまたユーザへ価値提供できる機能が作れるなというのは良かった点`}</strong>{`でした。`}</p>
    <h2>{`今後のアカデミーの技術的な方向性`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`これから解決していきたいアカデミーの技術的な課題のようなものってありますか?`}</strong></p>
    <p><strong parentName="p">{`岸田`}</strong>{`: 今回初めてプロダクトに生成 AI を導入しましたが、`}<strong parentName="p">{`プロダクトの他の機能にも取り入れることができないかなどは導入前よりスムーズに考えられるようになったので、ぜひ検討していきたい`}</strong>{`です。また、発展してディベロッパーエクスペリエンスの向上などに使えないかなども考えたいと思います。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: ちょっと話が AI ではなくなりますが、アカデミーのコードベースは DDD を行いつつ開発しているのですが、こちらの構造を`}<strong parentName="p">{`もっと最適化し、新しくアサインされたエンジニアもよりスムーズに開発できるようにしたい`}</strong>{`なとは考えています。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` `}<strong parentName="p">{`ありがとうございました!`}</strong></p>
    <h1>{`さいごに`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9eb0fc7373d2e73f36ba887cf617d7c5/eea4a/202311_dev_006.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABWlsooOcH/8QAGRABAQADAQAAAAAAAAAAAAAAAQMCBBIU/9oACAEBAAEFAsrvp2qckadTQb7ANbgZ/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhAjEyQf/aAAgBAQAGPwLmk4FjVinZoxlCrw//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/ISEp4MAUT3fkJzAYzchqqyGQJ9JUAM8n/9oADAMBAAIAAwAAABCfP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxDEf//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQXGB4f/aAAgBAQABPxBeqAKrS937FS60vp1OL5FWvlQKtWJDXUwcNgEgkQ4RXE//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "202311 dev 006",
            "title": "202311 dev 006",
            "src": "/static/9eb0fc7373d2e73f36ba887cf617d7c5/e5166/202311_dev_006.jpg",
            "srcSet": ["/static/9eb0fc7373d2e73f36ba887cf617d7c5/f93b5/202311_dev_006.jpg 300w", "/static/9eb0fc7373d2e73f36ba887cf617d7c5/b4294/202311_dev_006.jpg 600w", "/static/9eb0fc7373d2e73f36ba887cf617d7c5/e5166/202311_dev_006.jpg 1200w", "/static/9eb0fc7373d2e73f36ba887cf617d7c5/eea4a/202311_dev_006.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`元々アカデミー開発チームは、責任を持ってチャレンジしていこうという雰囲気があったと思うのですが、今回の ChatGPT を使った機能開発にもそうしたチームの雰囲気が良く作用しているなとインタビューをしていて感じました。`}</p>
    <p>{`また生成 AI の導入も「新しくブームになりそうだから取り入れる」という考えではなく「日頃から解決を考えていた課題を解決できそうだ」という視点から導入していたのが印象的でした。`}</p>
    <p>{`こんな雰囲気のアカデミー開発チームに興味が出た方は、ぜひカジュアルにお話しましょう!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      